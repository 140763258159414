exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-candy-index-tsx": () => import("./../../../src/pages/candy/index.tsx" /* webpackChunkName: "component---src-pages-candy-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-opening-md": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/content/articles/opening.md" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-articles-opening-md" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-licorice-md": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/content/candy/licorice.md" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-licorice-md" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-salty-md": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/content/candy/salty.md" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-salty-md" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-sweet-md": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/buildhome/repo/src/content/candy/sweet.md" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-content-candy-sweet-md" */)
}

